import { createAction } from 'redux-act';

import { filterNullDataPoints } from 'baas-ui/metrics/utils';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { MongoDataSourceType } from 'baas-ui/services/registry';
import { BaseRequestPayload } from 'baas-ui/types';
import {
  AppMetrics,
  BaasAdminClient,
  CreateAppRequest,
  GroupMetrics,
  MetricsRequestOptions,
  PartialApp,
  PartialServiceDesc,
} from 'admin-sdk';
import { AtlasCluster, AtlasDataLake, AtlasProduct } from 'admin-sdk';

import { DefaultDataSourceServiceName, RecaptchaPayload } from './types';
import { AppProduct } from './types';

const NAME = 'home/';

export const showNewAppModal = createAction(`${NAME}show new app modal`);
export const hideNewAppModal = createAction(`${NAME}hide new app modal`);
export const setError = createAction<string>(`${NAME}set error`);
export const setGroupId = createAction<string>(`${NAME}set group id`);
export const setCreateAppError = createAction<string>(`${NAME}set create app error`);
export const clearDataSourceErrors = createAction(`${NAME}clear data source errors`);
export const clearClusterProvisionToastState = createAction(`${NAME}clear cluster provision toast state`);

export interface AppsReqPayload {
  groupId: string;
}

interface AppReqPayload extends AppsReqPayload {
  request: CreateAppRequest;
}

export interface ProductAppsPayload extends AppsReqPayload {
  products: AppProduct[];
}

interface LinkClusterPayload extends BaseRequestPayload {
  clusterName: string;
}

interface GetClusterPayload extends AppsReqPayload {
  clusterName: string;
  fields?: string[];
}

interface GetDataLakesPayload extends AppsReqPayload {
  atlasProduct?: AtlasProduct;
}

interface LinkDataLakePayload extends BaseRequestPayload {
  dataLakeName: string;
}

export interface LoadGroupMetricsPayload extends BaseRequestPayload {
  options?: MetricsRequestOptions;
}

export const [countUsersActions, countUsers] = createActionsAndExecutor<BaseRequestPayload, number>(
  `${NAME}count users`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).users().count()
);

export const [loadAppsActions, loadApps] = createActionsAndExecutor<ProductAppsPayload, PartialApp[]>(
  `${NAME}load apps`,
  (client: BaasAdminClient, { groupId, products }) =>
    () =>
      client.apps(groupId).list(products.join(','))
);

export const [loadGroupMeasurementsActions, loadGroupMeasurements] = createActionsAndExecutor<
  Omit<LoadGroupMetricsPayload, 'appId'>,
  GroupMetrics
>(
  `${NAME}load group metrics`,
  (client, { groupId, options }) =>
    () =>
      client
        .private()
        .group(groupId)
        .metrics(options)
        .then((response) => ({
          metricsMap: Object.entries(response.metricsMap).reduce<Record<string, AppMetrics>>(
            (acc, [appId, appMetrics]) => ({
              ...acc,
              [appId]: {
                ...appMetrics,
                measurements: filterNullDataPoints(appMetrics.measurements),
              },
            }),
            {}
          ),
        }))
);

export const [createAppActions, createApp] = createActionsAndExecutor<AppReqPayload, PartialApp>(
  `${NAME}create app`,
  (client, { groupId, request }) =>
    () =>
      client.apps(groupId).create(request)
);

export const [deleteAppActions, deleteApp] = createActionsAndExecutor<BaseRequestPayload, void>(
  `${NAME}delete app`,
  (client, { groupId, appId }) =>
    () =>
      client.apps(groupId).app(appId).remove()
);

export const [getNewAppClusterStateActions, getNewAppClusterState] = createActionsAndExecutor<
  GetClusterPayload,
  AtlasCluster
>(
  `${NAME}get new app cluster state`,
  (client, { groupId, clusterName }) =>
    () =>
      client.private().group(groupId).atlasClusters().cluster(clusterName).get(['stateName'])
);

export const [getDefaultM0VersionActions, getDefaultM0Version] = createActionsAndExecutor<void, String>(
  `${NAME}get default m0 cluster version`,
  (client) => () => client.private().defaultM0ClusterVersion().get()
);

export const [getAtlasClustersActions, getAtlasClusters] = createActionsAndExecutor<AppsReqPayload, AtlasCluster[]>(
  `${NAME}load Atlas clusters`,
  (client, { groupId }) =>
    () =>
      client.private().group(groupId).atlasClusters().list()
);

export const [getAtlasDataLakesActions, getAtlasDataLakes] = createActionsAndExecutor<
  GetDataLakesPayload,
  AtlasDataLake[]
>(
  `${NAME}load Atlas Data Lakes`,
  (client, { groupId, atlasProduct }) =>
    () =>
      client.private().group(groupId).atlasDataLakes(atlasProduct).list()
);

export const [getAtlasServerlessInstancesActions, getAtlasServerlessInstances] = createActionsAndExecutor<
  AppsReqPayload,
  AtlasCluster[]
>(
  `${NAME}load Atlas Serverless Instances`,
  (client, { groupId }) =>
    () =>
      client.private().group(groupId).atlasServerlessInstances().list()
);

export const [verifyRecaptchaActions, verifyRecaptcha] = createActionsAndExecutor<RecaptchaPayload, void>(
  `${NAME}verify recaptcha`,
  (client, { token }) =>
    () =>
      client.private().spa().recaptcha().verify(token)
);

export const [linkClusterForNewAppActions, linkClusterForNewApp] = createActionsAndExecutor<
  LinkClusterPayload,
  PartialServiceDesc
>(`${NAME}link cluster for new app`, (client, { groupId, appId, clusterName }) => () => {
  const svcConfig = {
    name: DefaultDataSourceServiceName.Cluster,
    type: MongoDataSourceType.Atlas,
    config: { clusterName },
  };
  return client.apps(groupId).app(appId).services().create(svcConfig, true);
});

export const [linkAtlasDataLakeForNewAppActions, linkAtlasDataLakeForNewApp] = createActionsAndExecutor<
  LinkDataLakePayload,
  PartialServiceDesc
>(`${NAME}link Atlas Data Lake for new app`, (client, { groupId, appId, dataLakeName }) => () => {
  const svcConfig = {
    name: DefaultDataSourceServiceName.DataLake,
    type: MongoDataSourceType.DataFederation,
    config: { dataLakeName },
  };
  return client.apps(groupId).app(appId).services().create(svcConfig, true);
});

export const [getAtlasFlexClustersActions, getAtlasFlexClusters] = createActionsAndExecutor<
  AppsReqPayload,
  AtlasCluster[]
>(
  `${NAME}load Atlas Flex Clusters`,
  (client, { groupId }) =>
    () =>
      client.private().group(groupId).atlasFlexClusters().list()
);
